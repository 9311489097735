






















































































.form-checkbox {
  display: flex;
  align-items: flex-start;
}

.input {
  flex-shrink: 0;
  margin-top: 0.3125em;
}

.label {
  padding-left: 0.5em;
}
