































.form-columns {
  @media (min-width: 35rem) {
    display: grid;
    gap: 1.5em;
  }
}
