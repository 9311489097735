












































































.form-radio {
  display: flex;
  align-items: flex-start;

  &.is-inline {
    display: inline-flex;
  }
}

.input {
  flex-shrink: 0;
  margin-top: 0.3125em;
}

.label {
  padding-left: 0.5em;
}
