






















@use "../css/variables.scss";

.form-fieldset {
  padding: 1em;

  border: 0.125em solid variables.$color-gray-200;
  border-radius: variables.$border-radius;
}

.legend {
  padding: 0 0.125em;

  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.2;
}
