$color-primary: #c53030;
$color-primary-dark: #9b2c2c;

$color-gray-100: #f7fafc;
$color-gray-200: #e2e8f0;
$color-gray-400: #cbd5e0;
$color-gray-500: #a0aec0;
$color-gray-600: #718096;
$color-gray-700: #4a5568;
$color-gray-900: #1a202c;

$color-red-100: #fff5f5;
$color-red-700: #c53030;

$container-width: 50rem;
$container-padding: 1rem;

$border-radius: 0.25em;
