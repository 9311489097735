







































































































































































@use "../css/variables.scss";

.label {
  display: inline-block;
  font-weight: 700;
}

.help {
  font-size: 0.875em;
  color: variables.$color-gray-700;
}

.input {
  position: relative;
  display: block;
  width: 100%;
  padding: 0.375em 0.5em;

  background: variables.$color-gray-100;
  border: 0.125em solid variables.$color-gray-400;
  border-radius: variables.$border-radius;
  outline: 0;

  transition: border-color 0.15s;

  &:focus {
    border-color: variables.$color-gray-900;
  }
}

.errors {
  font-size: 0.875em;
}

.remove-file {
  margin-left: 0.2em;

  color: variables.$color-gray-900;
  background: variables.$color-gray-200;

  border-radius: variables.$border-radius;
}
